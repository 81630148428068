import request from '@/utils/request';

export async function fetchWinningList(): Promise<WinningList[]> {
  const {
    success,
    data,
  } = await request.get<ResponseWinningList>(
    `${process.env.VUE_APP_API_URL}/invoice/winning-list`,
  ).then(({ data }) => data);

  if (!success) {
    throw new Error();
  }

  return data;
}

export interface ResponseWinningList {
  success: boolean;
  data: WinningList[];
}

export interface WinningList {
  date: {
    year: number;
    months: number[];
  };
  prizes: WinningPrizeItem[];
}

export interface WinningPrizeItem {
  type: WinningPrizeType;
  amount: number;
  numbers?: string[];
}

export enum WinningPrizeType {
  Super = 'super',
  Special = 'special',
  First = 'first',
  Second= 'second',
  Third= 'third',
  Fourth = 'fourth',
  Fifth = 'fifth',
  Sixth = 'sixth',
  SixthExtra = 'sixth-extra',
}
