import { Vue, Component } from 'vue-property-decorator';
import { fetchWinningList, WinningList } from '@/api/invoice';
import { OgMeta } from '@/utils/seo/og-meta';
import { Meta } from '@/utils/seo/meta';
import WinningBox from './winning-box.vue';

@Component({
  components: {
    WinningBox,
  },
  head() {
    const title = this.$t('winning-list.title').toString();
    const description = this.$t('winning-list.description').toString();

    return {
      title,
      meta: [
        OgMeta.title(title),
        OgMeta.url(this.$route.path),
        Meta.description(description),
        OgMeta.description(description),
      ],
    };
  },
  async beforeRouteEnter(to, from, next) {
    const list = await fetchWinningList();

    next((vm) => {
      const self = vm as InvoiceWinningList;
      self.list = list;
    });
  },
})
export default class InvoiceWinningList extends Vue {
  list: WinningList[] | null = null;
}
