import { Title } from '@/utils/seo/title';

export class OgMeta {
  static title(content: string): OgMetaItem {
    return {
      vmid: 'og:title',
      property: 'og:title',
      template: Title.template,
      content,
    };
  }

  static url(_path?: string, _url?: string): OgMetaItem {
    _url = _url || 'https://moze.app';
    _path = _path || '';

    return {
      vmid: 'og:url',
      property: 'og:url',
      content: _url + _path,
    };
  }

  static description(content: string): OgMetaItem {
    return {
      vmid: 'og:description',
      property: 'og:description',
      content,
    };
  }

  static image(
    content: string,
    width: number,
    height: number,
    type: string = 'image/jpg',
  ): OgMetaItem[] {
    return [{
      vmid: 'og:image',
      property: 'og:image',
      content,
    }, {
      vmid: 'og:image:type',
      property: 'og:image:type',
      content: type,
    }, {
      vmid: 'og:image:width',
      property: 'og:image:width',
      content: width.toString(),
    }, {
      vmid: 'og:image:height',
      property: 'og:image:height',
      content: height.toString(),
    }];
  }

  static type(content?: string): OgMetaItem {
    return {
      vmid: 'og:type',
      property: 'og:type',
      content: content || 'website',
    };
  }
}

export interface OgMetaItem {
  vmid: string;
  property: string;
  content: string;
  template?: (chunk: string) => string;
}
